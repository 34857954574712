import { useRef, useState, useEffect, Suspense } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { OrbitControls, useGLTF } from "@react-three/drei";
import * as THREE from "three";
import { TypographyBodyDefault } from "components/StyledComponets/Typography";
import { ResetIcon } from "components/Icons";
import { PrimaryButton } from "components/StyledComponets/Button";

interface ThreeDViewerProps {
  modelUrl: string;
  scale: number;
  referenceObject: keyof typeof referenceModels; // Type for the referenceObject
}

const referenceModels = {
  CreditCard: "https://d2yydnugieiyqv.cloudfront.net/files/Credit_Card.glb",
  A4Paper: "https://d2yydnugieiyqv.cloudfront.net/files/A4_Paper.glb",
  TrafficCone: "https://d2yydnugieiyqv.cloudfront.net/files/Traffic_Cone.glb",
  Human:
    "https://d2yydnugieiyqv.cloudfront.net/files/dffef861-065b-448e-adc3-c10086e4e242_Human.glb",
};

function ThreeDViewer({ modelUrl, scale, referenceObject }: ThreeDViewerProps) {
  const modelRef = useRef<any>();
  const cardRef = useRef<any>();

  const { scene: modelScene } = useGLTF(modelUrl, true); // Load the main model

  const { scene: referenceScene } = useGLTF(
    referenceModels[referenceObject],
    true
  ); // Load the selected reference model

  const [cardPosition, setCardPosition] = useState([0, 0, 0]);

  useEffect(() => {
    if (modelRef.current) {
      const box = new THREE.Box3().setFromObject(modelRef.current);
      const size = new THREE.Vector3();
      box.getSize(size);

      const refBox = new THREE.Box3().setFromObject(cardRef.current);
      const refSize = new THREE.Vector3();
      refBox.getSize(refSize);

      const offset = size.x / 2 + scale * 0.05 + refSize.x / 2;
      setCardPosition([offset, 0, 0]);
    }
  }, [scale]);

  return (
    <>
      {/* Main 3D model */}
      <group ref={modelRef} scale={[scale, scale, scale]}>
        <primitive object={modelScene} />
      </group>

      {/* Reference object for size comparison */}
      <group ref={cardRef}>
        <primitive position={cardPosition} object={referenceScene} />
      </group>
    </>
  );
}

function CameraReset({
  controlsRef,
  resetCamera,
}: {
  controlsRef: any;
  resetCamera: boolean;
}) {
  const { camera } = useThree();

  useEffect(() => {
    if (resetCamera && controlsRef.current) {
      camera.position.set(0, 0, 0);
      controlsRef.current.reset();
    }
  }, [resetCamera, controlsRef, camera]);

  return null;
}

function AxesHelperComponent({ controlsRef }: { controlsRef: any }) {
  const axesRef = useRef<THREE.AxesHelper>(null);
  const { camera } = useThree();

  const rotationOffset = new THREE.Euler(0, Math.PI / 4, 0);

  useFrame(() => {
    if (axesRef.current && controlsRef.current && controlsRef.current.object) {
      const controlsObject = controlsRef.current.object;
      axesRef.current.rotation.copy(controlsObject.rotation);

      axesRef.current.rotation.x += rotationOffset.x;
      axesRef.current.rotation.y += rotationOffset.y;
      axesRef.current.rotation.z += rotationOffset.z;

      axesRef.current.rotation.order = "XYZ";
    }
  });

  return <axesHelper ref={axesRef} args={[5]} />;
}

export default function Viewer({ modelUrl }: { modelUrl: string }) {
  const [scale, setScale] = useState(1);
  const [resetCamera, setResetCamera] = useState(false);
  const [referenceObject, setReferenceObject] =
    useState<keyof typeof referenceModels>("CreditCard"); // Typed state
  const controlsRef = useRef<any>();

  const handleScaleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setScale(Number(event.target.value));
  };

  const handleReset = () => {
    setScale(1);
    setResetCamera(true);
  };

  useEffect(() => {
    if (resetCamera) {
      setTimeout(() => setResetCamera(false), 100);
    }
  }, [resetCamera]);

  return (
    <div style={{ position: "relative" }}>
      <PrimaryButton
        onClick={handleReset}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          padding: "5px 10px",
          backgroundColor: "#f0f0f0",
          border: "1px solid #ccc",
          borderRadius: "5px",
          cursor: "pointer",
          zIndex: 1,
        }}
      >
        {ResetIcon}
      </PrimaryButton>

      <div
        style={{
          width: "100%",
          height: "500px",
          background: "linear-gradient(to bottom, #e0e0e0, #b0b0b0)",
        }}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <Canvas
            camera={{ position: [0, 0, 5], fov: 50 }}
            frameloop="demand"
            onCreated={({ gl }) => {
              if (!gl.getContext()) {
                console.error("WebGL not supported on this device.");
                // Handle gracefully, e.g., show a fallback UI
              }
            }}
          >
            <ThreeDViewer
              modelUrl={modelUrl}
              scale={scale}
              referenceObject={referenceObject}
            />
            <OrbitControls ref={controlsRef} enableZoom={true} />
            <CameraReset controlsRef={controlsRef} resetCamera={resetCamera} />
            <ambientLight intensity={0.5} />
            <directionalLight position={[10, 10, 5]} />
          </Canvas>
        </Suspense>
      </div>

      <div style={{ textAlign: "center", marginTop: "10px" }}>
        <label>
          <TypographyBodyDefault>
            Scale: {scale.toFixed(1)}
          </TypographyBodyDefault>
        </label>
        <input
          type="range"
          min="0.1"
          max="5"
          step="0.1"
          value={scale}
          onChange={handleScaleChange}
        />
      </div>

      {/* Reference Object Selection */}
      <div style={{ textAlign: "center", marginTop: "10px" }}>
        <label>
          <TypographyBodyDefault>Reference Object:</TypographyBodyDefault>
        </label>
        <select
          value={referenceObject}
          onChange={(e) =>
            setReferenceObject(e.target.value as keyof typeof referenceModels)
          }
        >
          <option value="CreditCard">Credit Card</option>
          <option value="A4Paper">A4 Paper</option>
          <option value="TrafficCone">Traffic Cone</option>
          <option value="Human">Human</option>
        </select>
      </div>
    </div>
  );
}

useGLTF.preload("/path/to/your/model.glb", true);

import React, { useContext, useEffect, useState } from "react";
import { getApi, postApi, deleteApi } from "../../utils/fetchUtils";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Modal,
  Box,
  Icon,
  TextField,
  Button,
  Checkbox,
  TableFooter,
} from "@mui/material";
import ThreeDViewer from "./ThreeDViewer";

interface ThreeDViewerModalProps {
  open: boolean;
  onClose: () => void;
  modelUrl: string;
}

const ThreeDViewerModal: React.FC<ThreeDViewerModalProps> = ({
  open,
  onClose,
  modelUrl,
}) => {
    console.log(modelUrl);
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        component="div"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800,
          background: "var(--color-palette-gray-800, #253042)",
          boxShadow: 24,
          borderRadius: 4,
          p: 4,
        }}
      >
        {/* <ThreeDViewer modelUrl={modelUrl.replace("https://d2yydnugieiyqv.cloudfront.net/files/", "http://localhost:8005/")} /> */}
        <ThreeDViewer modelUrl={modelUrl} />
      </Box>
    </Modal>
  );
};

export default ThreeDViewerModal;

export enum ROUTE_PATH_MAP {
  "HOME" = "/",
  "CREATE" = "/create",
  "FOLDERS" = "/folders",
  "RENDERS" = "/renders",
  "ASSETS" = "/assets",
  "SETTING" = "/Setting",
  "VIDEO_EDITOR" = "/editor",
  "PROMPTS" = "/prompts",
  "EDITOR_WIDGET" = "/editor_widget",
  "TEST" = "/test",

  // "EDITOR_NAVBAR" = "/editorNavbar",
  // "EDITOR_PANEL" = "/editorPanel",
}

export enum MODAL_MODE {
  "EDITING" = "EDITING",
  "DISPLAY" = "DISPLAY",
  "UPLOAD" = "UPLOAD",
}
export enum SEVERITY {
  "ERROR" = "error",
  "WARNING" = "warning",
  "INFO" = "info",
  "SUCCESS" = "success",
}

export const USER_LOGOUT = "USER_LOGOUT";

export enum TOKEN_SOURCE {
  "AUTH0" = "AUTH0",
  "LOGIN" = "LOGIN",
  "SESSION" = "SESSION",
}

export enum EXPORT_OPTIONS {
  "TXT" = "TXT",
  "CSV" = "CSV",
}

export enum PROJECT_TYPE {
  "EMPTY" = "EMPTY",
  "VIDEO" = "VIDEO",
  "BILLBOARD" = "BILLBOARD",
  "UNREAL" = "UNREAL",
  "UNREAL_SCENE" = "UNREAL_SCENE",
  "STABLE_DIFFUSION" = "STABLE_DIFFUSION",
  "IMAGE_EDITOR" = "IMAGE_EDITOR",
  "IMAGE" = "IMAGE",
  "TEXT_GENERATION" = "TEXT_GENERATION",
  "IMAGE_GENERATION" = "IMAGE_GENERATION",
  "FACEBOOK_AD" = "FACEBOOK_AD",
  "IMAGE_SET" = "IMAGE_SET",
}
export enum SCOPE {
  "BRANDKIT" = "BRANDKIT",
  "BRAND" = "BRAND",
  "PRIVATE" = "PRIVATE",
  "GLOBAL" = "GLOBAL",
}
export enum USER_ROLE {
  "ADMIN" = "ADMIN",
  "EDITOR" = "EDITOR",
  "VIEWER" = "VIEWER",
}
export enum INVITE_STATUS {
  "PENDING" = "PENDING",
  "EXPIRED" = "EXPIRED",
  "SIGNUP" = "SIGNUP",
  "VERIFIED" = "VERIFIED",
  "COMPLETED" = "COMPLETED",
}
export enum ASSET_TYPE {
  "VIDEO" = "VIDEO",
  "IMAGE" = "IMAGE",
  "COLOR" = "COLOR",
  "FONT" = "FONT",
  "GREEN_SCREEN" = "GREEN_SCREEN",
  "3D_PRODUCT" = "3D_PRODUCT",
  "3D_MAP" = "3D_MAP",
  "3D_ANIMATION" = "3D_ANIMATION",
  "HIDDEN" = "HIDDEN",
  "PRODUCT" = "PRODUCT",
  "BACKGROUND" = "BACKGROUND",
  "BACKGROUND_DISPLAY" = "BACKGROUND_DISPLAY",

  // frontend only
  "TEXT" = "TEXT",
  "LIBRARY" = "LIBRARY",
  "BRANDKIT" = "BRANDKIT",
  "3D_SCENE" = "3D_SCENE",
  "TEMPLATE" = "TEMPLATE",
}

// export enum CATEGORY_TYPE {
//   "FOOTWEAR" = "FOOTWEAR",
//   "BEAUTY" = "BEAUTY",
//   "AUTOMOBILE" = "AUTOMOBILE",
//   "BANKING" = "BANKING",
// }

export enum DURATION_TYPE {
  "ZERO" = "ZERO",
  "FIVE" = "FIVE",
  "TEN" = "TEN",
  "FIFTEEN" = "FIFTEEN",
}

export enum RATIO_TYPE {
  "16_9" = "16_9",
  "9_16" = "9_16",
  "1_1" = "1_1",
  "4_5" = "4_5",
}

export enum OPTIONS_TYPE {
  "PRODUCT_CATEGORY" = "PRODUCT_CATEGORY_OPTIONS",
  "DURATION" = "DURATION_OPTIONS",
  "VIEW" = "VIEW_OPTIONS",
  "ASPECT_RATIO" = "ASPECT_RATIO_OPTIONS",
  "MULTIPLE_SCENES" = "MULTIPLE_SCENES",
}

export enum CREATE_VIDEO_PROJECT_STEP {
  "ADD_DETAILS" = "ADD_DETAILS",
  "CHOOSE_SCENE" = "CHOOSE_SCENE",
  "CHOOSE_CAMERA_ANIMATION" = "CHOOSE_CAMERA_ANIMATION",
}

export enum LEFT_MENU_OPTION {
  "NULL" = "EMPTY",
  "TEMPLATE" = "TEMPLATE",
  "SCENE" = "SCENE",
  "ENVIRONMENT" = "ENVIRONMENT",
  "OBJECT" = "OBJECT",
  "MODEL" = "MODEL",
  "INSERT" = "INSERT",
  "ANIMATION" = "ANIMATION",
  "CAMERA" = "CAMERA",
  "PRODUCT" = "PRODUCT",
  "DEBUG" = "DEBUG",
  "CAMERA_PROPERTIES" = "CAMERA_PROPERTIES",
  "OBJECT_ANIMATION_PROPERTIES" = "OBJECT_ANIMATION_PROPERTIES",
  "ANIMATION_PROPERTIES" = "ANIMATION_PROPERTIES",
  "TEST" = "TEST",
  "RENDER_SETTINGS" = "RENDER_SETTINGS",
}

export const LIGHTING_TYPE: { display: string; value: string }[] = [
  { display: "SpotLight", value: "Spot_Light" },
  { display: "RectLight", value: "Rect_Light" },
  { display: "PointLight", value: "Point_Light" },
];
export const SKY_TYPE: string[] = [
  "Clear",
  "Rainy",
  "Cloudy",
  "Partly Cloudy",
  "Overcast",
  "Thunderstorm",
  "Snowy",
  "Custom",
];
export const SKY_MODE: string[] = [
  "Volumetric Clouds",
  "No Clouds",
  "Static Clouds",
  "2D Dynamic Clouds",
  "Volumetric Aurora",
  "Space",
];
export const COLOR_MODE: string[] = ["Sky Atmosphere", "Simplified Color"];
export const ANIMATION_TYPE: string[] = ["AutoInAutoOut", "AutoInLinearOut", "LinearInAutoOut", "LinearInLinearOut"];
export const CAMERA_ASPECT_RATIO_TYPE: string[] = [
  "16:9",
  "9:16",
  "1:1",
  "4:5",
];
export const RENDER_QUALITY_OPTIONS: string[] = [
  "Low",
  "Medium",
  "High",
  //  "Epic", "Cinematics"
];
export const RENDER_EXPORT_OPTIONS: string[] = [
  "Video",
  "Image",
  "ImageSequence"
];
export const FRAME_RATE_OPTIONS: string[] = [
  "12",
  "15",
  "24",
  "30",
  "60",
  "90",
  "120",
  "200"
];
export const GRAPHICS_SETTINGS: string[] = [
  "Low",
  "Medium",
  "High",
  "Epic",
  "Cinematics",
];
export const GLOBAL_ILLUMINATIONS: string[] = ["None", "SSGI", "Lumen"];
export const REFLATION_OPTIONS: string[] = ["None", "SSR", "Lumen"];
export const TEXTURE_STREAMING_OPTIONS: string[] = [
  "Low",
  "Medium",
  "High",
  "Epic",
  "Cinematics",
];
export enum EDITOR_OPTION_TYPE {
  SLIDER = "SLIDER",
  CHECKBOX = "CHECKBOX",
}
export enum TASK_STATUS {
  "PENDING" = "PENDING",
  "SUCCESS" = "SUCCESS",
  "FAILURE" = "FAILURE",
}
export enum ASSETS_TABLE_OPTIONS {
  "CAMERA_ANIMATIONS" = "CAMERA_ANIMATIONS",
  "PRODUCTION_ANIMATIONS" = "PRODUCTION_ANIMATIONS",
  "PRODUCTS" = "PRODUCTS",
  "PRODUCT_CATEGORIES" = "PRODUCT_CATEGORIES",
  "MAPS" = "MAPS",
  "TAGS" = "TAGS",
  "BRANDS" = "BRANDS",
  "USERS" = "USERS",
}
export enum ASSETS_TABLE_OPTIONS_URL {
  CAMERA_ANIMATIONS = "cameraAnimations",
  PRODUCTION_ANIMATIONS = "productAnimations",
  PRODUCTS = "products",
  PRODUCT_CATEGORIES = "productCategories",
  MAPS = "maps",
  TAGS = "tags",
  BRANDS = "brands",
  USERS = "users"
}
export const AssetsOptions = [ASSETS_TABLE_OPTIONS.CAMERA_ANIMATIONS, ASSETS_TABLE_OPTIONS.PRODUCTION_ANIMATIONS,
ASSETS_TABLE_OPTIONS.PRODUCTS, ASSETS_TABLE_OPTIONS.PRODUCT_CATEGORIES,
ASSETS_TABLE_OPTIONS.MAPS, ASSETS_TABLE_OPTIONS.TAGS, ASSETS_TABLE_OPTIONS.BRANDS, ASSETS_TABLE_OPTIONS.USERS];